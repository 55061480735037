import React from 'react';

// UI library components
import Heading from '@react/react-spectrum/Heading';
import Button from '@react/react-spectrum/Button';
import Textfield from '@react/react-spectrum/Textfield';
import Checkbox from '@react/react-spectrum/Checkbox';
import CircularProgress from '@material-ui/core/CircularProgress';
import Select from '@react/react-spectrum/Select';
import { Grid } from '@material-ui/core';
import NumberInput from '@react/react-spectrum/NumberInput';

// Custom components
import ConfirmationDialog from '../form/FormConfirmationDialog';

// Custom styling and utils
import './QuickCreatePage.css';
import { MembershipObject } from '../../../models/memberships/Membership';
import { createInvalidMembership } from '../../../providers/MembershipsAPI';
import { MembershipResponse } from '../../../models/memberships/MembershipResponse';

import { ErrorResponse } from '../../../models/ErrorResponse';
import ErrorDialog from '../../../../src/utils/Dialogs/ErrorDialog';
import ThreeYCStatus from "../../../constants/ThreeYCStatus";
import { getMarketSegmentOptions, getSubSegmentOptions } from '../../../utils/Functions/utils';
import { isLMflagDevEnabled, isLMflagEnabled, isTransferWWFlagDevEnabled, isTransferWWFlagEnabled } from '../../../config/FeatureFlags';

function QuickCreatePage() {
  const [createdMembership, setCreatedMembership] = React.useState(false);
  const [lmGroupId, setLmGroupId] = React.useState('');
  const [createdMembershipId, setCreatedMembershipId] = React.useState('');
  const [createdMembershipEligibility, setCreatedMembershipEligibility] = React.useState('');
  const [createdMembershipErrorCode, setCreatedMembershipErrorCode] = React.useState('');
  const [createdMembershipErrorMessage, setCreatedMembershipErrorMessage] = React.useState('');
  const [createdMembership3YCStatus, setCreatedMembership3YCStatus] = React.useState('');
  const [marketSegment, setMarketSegment] = React.useState<string>('COM');
  const [marketSubSegments, setMarketSubSegments] = React.useState<string[]>([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const [companyName, setCompanyName] = React.useState('');
  const [linkedMembershipId, setLinkedMembershipId] = React.useState('');
  const [isInvalid, setIsInvalid] = React.useState(false);
  const [numEligibleOffers, setNumEligibleOffers] = React.useState(1);
  const [isError, setIsError] = React.useState(false);
  const [is3YCEnabled, setIs3YCEnabled] = React.useState(false);
  const [isLMOwner, setIsLMOwner] = React.useState(false);
  const [isLMMember, setIsLMMember] = React.useState(false);
  const [isWorldWideEnabled, setIsWorldWideEnabled] = React.useState(false);
  const [errorResponse, setErrorResponse] = React.useState<ErrorResponse | undefined>();

  const getMembershipObject = (companyName: string, numEligibleOffers: number, marketSegment: string, marketSubSegments: string[], id: string): MembershipObject => {
    let membership: MembershipObject
    membership = {
      companyName: companyName,
      contacts: [
        {
          firstName: 'Default FirstName',
          lastName: 'Default LastName',
          phoneNumber: '123456789',
          email: 'cme527-sandbox-contact@adobe.com'
        }
      ],
      offerData: {
        numEligibleOffers: numEligibleOffers
      },
      marketSegment,
      marketSubSegments
    }
      if (isWorldWideEnabled) {
        membership.globalSalesEnabled = true;
        membership.quickCreateEnabled = true;
        membership.offerData = {
          numEligibleOffers : 0
        }
      }
      if(isLMOwner){
        membership.linkedMember = true;
        membership.linkedContract={
          type: "STANDARD",
          status: "ACTIVE",
          orgName: "LM1234",
          marketSegment: marketSegment,
          migrationStatus: "PARTIALLY_MIGRATED"
        }
      }
      if(isLMMember){
        membership.linkedMember = true;
        membership.linkedContract = {
          id: linkedMembershipId,
        }
      }
      if(is3YCEnabled){
         membership.commitment = {
          status: ThreeYCStatus.COMMITTED,
          startDate: "2022-07-17",
          endDate: "2025-04-28",
          minimumQuantities: [
            {
              offerType: "LICENSE",
              quantity: 10
            },
            {
              offerType : "CONSUMABLES",
              quantity: 500
            }
          ]
        }
      }
      else {
        membership.commitment = {
          status: ThreeYCStatus.INACTIVE
        }
      }
    return membership
  };

  const handleQuickCreateSubmit = () => {
    if (companyName === '' || (isLMMember && linkedMembershipId === '')) {
      setIsInvalid(true);
    } else {
      setIsLoading(true);
      setIsInvalid(false);
      let membershipObject = getMembershipObject(companyName, numEligibleOffers, marketSegment, marketSubSegments, linkedMembershipId);
      createInvalidMembership(membershipObject).then((obj: MembershipResponse) => {
        if(obj.httpstatus == 200) {
          setIsError(false)
          setIsLoading(false);
          setCompanyName('');
          setLinkedMembershipId('');
          setNumEligibleOffers(1);
          setIsWorldWideEnabled(false);
          setCreatedMembership(true);
          if (isLMMember || isLMOwner) {
            setLmGroupId(obj?.linkedContract?.id);
          }
          setCreatedMembershipId(obj.membershipId);
          setCreatedMembershipErrorCode(obj.ineligibleCode);
          setCreatedMembershipEligibility(obj.eligibilityStatus);
          setCreatedMembershipErrorMessage(obj.ineligibleMessage);
        } else {
          setIsError(true)
          setIsLoading(false);
          setErrorResponse(obj)
        }
      });
    }
  };
  const handleDismissErrorDialog = () => {
    sessionStorage.clear();
    window.location.reload();
  };

  const handleDismissDialog = () => {
    setCreatedMembership(false);
  };

  return (
    <div>
      <ConfirmationDialog
        createdMembership={createdMembership}
        lmGroupId={lmGroupId}
        createdMembershipId={createdMembershipId}
        createdMembershipEligibility={createdMembershipEligibility}
        createdMembershipErrorCode={createdMembershipErrorCode}
        createdMembershipErrorMessage={createdMembershipErrorMessage}
        handleDismissDialog={handleDismissDialog}
      />
      <div className='quick-create-heading-container'>
        <Heading variant='subtitle1'>Quick Create Eligible Membership ID</Heading>
        {isLoading ? (
          <CircularProgress size={20} />
        ) : [
          (errorResponse && isError ?
          <ErrorDialog
          isError={true}
          status={errorResponse.httpstatus}
          reason={errorResponse.reason}
          message={errorResponse.message}
          handleDismissErrorDialog={handleDismissErrorDialog}
          />
          :
          <Button variant='cta' onClick={event => handleQuickCreateSubmit()}>
            Create
          </Button> )
        ]}
      </div>
      <Heading variant='subtitle3'>Select company name and number of eligible offers. Address will be distributor address.</Heading>
      <br />
      <Grid container spacing={2}>
        <Grid item md={6} xs={12}>
          <p className='quick-create-field-label'>Company Name</p>
          <Textfield
            className='quick-create-textfield'
            value={companyName}
            validationState={isInvalid && companyName.trim() === '' ? 'invalid' : undefined}
            onChange={value => setCompanyName(value)}
          />
        </Grid>
        <Grid item md={3} xs={12}>
          <p className='quick-create-field-label'>Number of Eligible Offers (chosen at random)</p>
          <div className='quick-create-number-input-container'>
            <NumberInput
              min={0}
              max={1000}
              disabled={isWorldWideEnabled}
              value={numEligibleOffers}
              onChange={value => {
                if (value) {
                  setNumEligibleOffers(value);
                }
              }}
            />
          </div>
        </Grid>
        <Grid container spacing={3}>
        <Grid item md={3} xs={12}>
          <p className='quick-create-field-label'>Market Segment</p>
          <div className='quick-create-number-select-container'>
            <Select
              aria-label='Market segment'
              placeholder="Market Segment"
              value={marketSegment}
              options={getMarketSegmentOptions()}
              onSelect={value => {
                const selectedMarketSegment = value as string
                setMarketSegment(selectedMarketSegment)
              }}
            />
          </div>
        </Grid>
        {marketSegment === 'EDU' && (
        <Grid item md={3} xs={12}>
          <p className='quick-create-field-label'>Sub Segments</p>
          <div className='quick-create-number-select-container'>
            <Select
              multiple
              aria-label='Sub Segments'
              placeholder="Sub Segments"
              value={marketSubSegments}
              options={getSubSegmentOptions(marketSubSegments)}
              onSelect={value => {
                const selectedMarketSubSegments = value as unknown
                setMarketSubSegments(selectedMarketSubSegments as string[])
              }}
            />
          </div>
        </Grid>
      )}
      </Grid>
      <Grid>
        <div style={{ display: 'flex', flexDirection: 'column', marginTop: 20 }}>
          {(isLMflagDevEnabled() || isLMflagEnabled()) && (
            <>
              <div>
                <Checkbox
                  checked={isLMOwner}
                  onChange={setIsLMOwner}
                  disabled={is3YCEnabled || isLMMember}
                >
                  Linked Membership Owner
                </Checkbox>
              </div>
            </>
          )}
          <div style={{ display: 'flex', justifyContent: 'left', alignItems: 'center' }}>
              {(isLMflagDevEnabled() || isLMflagEnabled()) && (
                <>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div>
                      <Checkbox
                        checked={isLMMember}
                        onChange={setIsLMMember}
                        disabled={isLMOwner || is3YCEnabled}
                      >
                        Linked Membership Member
                      </Checkbox>
                    </div>
                    {isLMMember && (
                      <div>
                        <p className='quick-create-field-label'>Linked Membership Group ID</p>
                        <Textfield
                          className='quick-create-textfield'
                          value={linkedMembershipId}
                          validationState={isInvalid && linkedMembershipId.trim() === '' ? 'invalid' : undefined}
                          onChange={value => setLinkedMembershipId(value)}
                        />
                    </div>
                  )}
                </div>
                </>   
              )}
            </div>
          <div style={{ display: 'flex', justifyContent: 'left', alignItems: 'center' }}>
            <div>
              <Checkbox
                checked={is3YCEnabled}
                onChange={setIs3YCEnabled}
                disabled={isLMOwner || isLMMember}
              >
                3YC Eligible
              </Checkbox>
            </div>

          </div>
          {(isTransferWWFlagDevEnabled() || isTransferWWFlagEnabled()) && (
              <div>
                <Checkbox
                  checked={isWorldWideEnabled}
                  onChange={(value) => {
                    setNumEligibleOffers(0);
                    setIsWorldWideEnabled(value);
                  }}
                >
                  Enable WorldWide
                </Checkbox>
              </div>
            )}

        </div>
      </Grid>
      </Grid>
    </div>
  );
}

export default QuickCreatePage;
