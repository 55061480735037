import {Environment, getEnvironment} from "./EnvironmentConfig";
import {FloodGateResponse} from "../service/FloodgateService";

let rcpFlag: boolean;
let rcpFlagDev: boolean;

export function isRcpFlagEnabled(): boolean {
    return rcpFlag || getEnvironment() === Environment.LOCAL;
}

export function isRcpFlagDevEnabled(): boolean {
    return rcpFlagDev || getEnvironment() === Environment.LOCAL;
}

let createPaFlag: boolean;
let createPaFlagDev: boolean;

export function isCreatePaFlagEnabled(): boolean {
    return createPaFlag || getEnvironment() === Environment.LOCAL;
}

export function isCreatePaFlagDevEnabled(): boolean {
    return createPaFlagDev || getEnvironment() === Environment.LOCAL;
}


let worldWideFlag: boolean;
let worldWideFlagDev: boolean;
let transferWWFlag: boolean;
let transferWWFlagDev: boolean;
let retailFlowFlag: boolean;
let retailFlowFlagDev: boolean;
let linkedMembershipFlag: boolean;
let linkedMembershipFlagDev: boolean;

export function isWorldWideFlagEnabled(): boolean {
    return worldWideFlag || getEnvironment() === Environment.LOCAL;
}

export function isWorldWideFlagDevEnabled(): boolean {
    return worldWideFlagDev || getEnvironment() === Environment.LOCAL;
}

export function isTransferWWFlagEnabled(): boolean {
    return transferWWFlag || getEnvironment() === Environment.LOCAL;
}

export function isTransferWWFlagDevEnabled(): boolean {
    return transferWWFlagDev || getEnvironment() === Environment.LOCAL;
}

export function isRetailFlowFlagEnabled(): boolean {
    return retailFlowFlag || getEnvironment() === Environment.LOCAL;
}

export function isRetailFlowFlagDevEnabled(): boolean {
    return retailFlowFlagDev || getEnvironment() === Environment.LOCAL;
}

export function isLMflagEnabled(): boolean {
    return linkedMembershipFlag || getEnvironment() === Environment.LOCAL;
}

export function isLMflagDevEnabled(): boolean {
    return linkedMembershipFlagDev || getEnvironment() === Environment.LOCAL;
}

export const isFlagEnabled = (response : FloodGateResponse) => {
    rcpFlag = response?.releases[0]?.features.includes('sandbox_ui_rcp_changes_enabled');
    rcpFlagDev = response?.releases[0]?.features.includes('sandbox_ui_rcp_changes_enabled_dev');
    worldWideFlag = response?.releases[0]?.features.includes('sandbox_ui_worldwide_changes_enabled');
    worldWideFlagDev = response?.releases[0]?.features.includes('sandbox_ui_worldwide_changes_enabled_dev');
    transferWWFlag = response?.releases[0]?.features.includes('sandbox_ui_transfer_ww_changes_enabled');
    transferWWFlagDev = response?.releases[0]?.features.includes('sandbox_ui_transfer_ww_changes_enabled_dev');
    retailFlowFlag = response?.releases[0]?.features.includes('sandbox_ui_retail_flow_enabled_flow');
    retailFlowFlagDev = response?.releases[0]?.features.includes('sandbox_ui_retail_flow_enabled_dev');
    createPaFlag = response?.releases[0]?.features.includes('sandbox_ui_create_pa_enabled');
    createPaFlagDev = response?.releases[0]?.features.includes('sandbox_ui_create_pa_enabled_dev');
    linkedMembershipFlag = response?.releases[0]?.features.includes('sandbox_ui_lm_changes_enabled');
    linkedMembershipFlagDev = response?.releases[0]?.features.includes('sandbox_ui_lm_changes_enabled_dev');
}