// Node packages
import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Table, TableBody, TableContainer, TableHead, TableRow } from '@material-ui/core';
import MuiTableCell from '@material-ui/core/TableCell';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import SubscriptionRow from '../SubscriptionRow/SubscriptionRow';

// Custom styling and utils
import './SubresourcesTable.css';
import * as utils from '../../service/TableService';
import Button from '@react/react-spectrum/Button';
import { RecordObject } from '../../models/ResourceModels';

const CondensedTableCell = withStyles({
  root: {
    borderBottom: 'none'
  }
})(MuiTableCell);

const useTableStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset'
    }
  },
  expandCell: {
    padding: '0px 75px 0px 75px'
  }
});

interface TableProps<T> {
  objects: T[];
  subresourceType: string;
  resourceId: string;
  properties: {
    key: keyof T;
    title: string;
  }[];
  handleViewSubresource: (subresourceType: string, searchText: string) => void;
}

interface RowProps<T> {
  object: T;
  subresourceType: string;
  resourceId: string;
  properties: {
    key: keyof T;
    title: string;
  }[];
  handleViewSubresource: (subresourceType: string, searchText: string, modalData?: any, resourceId?: string) => void;
}

// Renders the rows of the table.
function NonEditableRow<T extends RecordObject>(props: RowProps<T>) {
  const classes = useTableStyles();
  const { object, properties, subresourceType, handleViewSubresource, resourceId } = props;

  return (
    <React.Fragment>
      <TableRow className={classes.root}>
        {properties.map(property =>
          String(property.key) === 'status' ? (
            <CondensedTableCell key={String(property.key)} align='center'>
              <div className={`subresources-status-${utils.getRecordStatus(object['status'])}`}>
                {object[property.key]}
              </div>
            </CondensedTableCell>
          ) : (
            <CondensedTableCell key={String(property.key)}>{object[property.key]}</CondensedTableCell>
          )
        )}
        <CondensedTableCell>
          <Button variant='action' onClick={() => handleViewSubresource(subresourceType, object['id'], object, resourceId)}>
            View
          </Button>
        </CondensedTableCell>
      </TableRow>
    </React.Fragment>
  );
}

// Renders the entire subresources table.
function SubresourcesTable<T extends RecordObject>(props: React.PropsWithChildren<TableProps<T>>) {
  const { objects, properties, handleViewSubresource, subresourceType, resourceId } = props;
  const SubresourceRow = ['subscriptions', 'retailsubscriptions'].includes(subresourceType) ? SubscriptionRow : NonEditableRow;

  const renderTable = () => {
    return (
      <TableContainer>
        <Table size='small' style={{ width: subresourceType === 'deployments' ? '30%' : '100%'}}>
          <TableHead>
            <TableRow>
              {properties.map(property => {
                if (property.title === 'Created Date') {
                  return (
                    <CondensedTableCell key={String(property.key)} className='subresources-table-head'>
                      {property.title} <ArrowDownwardIcon className='subresources-table-head-icon' />
                    </CondensedTableCell>
                  );
                } else {
                  return (
                    <CondensedTableCell key={String(property.key)} className='subresources-table-head'>
                      {property.title}
                    </CondensedTableCell>
                  );
                }
              })}
              <CondensedTableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {objects.map((object, index) => (
              <SubresourceRow
                key={index}
                object={object}
                resourceId={resourceId}
                properties={properties}
                subresourceType={subresourceType}
                handleViewSubresource={handleViewSubresource}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  return <div>{objects.length === 0 ? <p>No Data</p> : renderTable()}</div>;
}

export default SubresourcesTable;
